// Contains global app context for SPA
import React from 'react';

// Initial AppContext
const defaultContext = {
    verify: false,
};

export const AppContext = React.createContext(defaultContext);

/* State Management Functions */

/* Mock State Helpers */
export const getMockVerifyState = (context) => {
    let setter = val => context.setState(state => ({...state, verify: val}));
    return [context.state.verify, setter];
}

/* Context Wrapping Component */
export default function AppContextWrap(props) {

    const [context, setContext] = React.useState(defaultContext);
    const value = {state: context, setState: setContext};

    return (
        <AppContext.Provider value={value}>
            {props.children}
        </AppContext.Provider>
    );
}
