import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css'
import 'style/main.scss';
import 'assets/NHaasGroteskTXStd-55Rg_7552231.ttf';
import 'assets/NHaasGroteskTXStd-75Bd_2117677782.ttf'
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
