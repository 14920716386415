import React, { useEffect, useState } from 'react';
import { AppContext, getMockVerifyState } from 'context/AppContext.jsx';
import { Dimmer, Loader, Grid, Menu, Image, Segment, Container, Header, Input, Button } from 'semantic-ui-react';

import TransGif from 'assets/transparency-hero-gif-1272x477_0.gif'
import VeriCheckmark from 'assets/VZ_BLOCKCHAIN_GRAPHIC_WHITE_RGB.png';
import Alert from 'assets/alert_blk.png'

import lstyle from './MainView.module.scss';

import Verify from 'utils/verify.js';

const verifyServer = 'http://34.134.159.27/';

function MainView(props) {
    // AppContext Helper access to AppContext.verify
    const appContext = React.useContext(AppContext);
    const [ctxVerify, setCtxVerify] = getMockVerifyState(appContext); // Noted as ctx => context
    // URL input state
    const [verifyURL, setURL] = useState('');
    // Verification data returned from server
    const [verifyData, setVerifyData] = useState(false);
    // URL timeout
    const [isValidUrl, setValidUrl] = useState(true)
    const [URLTimeout, setURLTimeout] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    // Add verify class to store
    useEffect(() => {
        if (!ctxVerify) {
            let newVerify = new Verify(adapterCb, verifyServer);
            setCtxVerify(newVerify);
        }
    }, [ctxVerify]); // eslint-disable-line react-hooks/exhaustive-deps 

    // Handle changes to the url input
    const handleChange = (e) => {
        setURL(e.target.value)
        checkValidUrl(e.target.value)
        setErrMsg(false);
    }
    // Handle submit from url input
    const handleSubmit = (e, v, revision) => {
        if (!verifyURL || verifyURL === "") {
            return;
        }
        if (revision) {
            setURL(revision)
        }
        ctxVerify.verifyData(revision ? revision.split(" ").join("") : verifyURL.split(" ").join(""));
    }

    const checkValidUrl = (url) => {
        if (URLTimeout) {
            clearTimeout(URLTimeout)
        }
        let timeoutId = setTimeout(() => {
            if (url === "") {
                setValidUrl(true);
                return
            }
            url = url.split(" ").join("")
            try {
                let isVal = url.startsWith("https://www.")
                if (!isVal) {
                    throw new Error("invalid")
                }
                new URL(url)
            } catch (e) {
                setValidUrl(false);
                return
            }
            setValidUrl(true);
            return
        }, 500)
        setURLTimeout(timeoutId)
    };

    const getValid = () => {
        return isValidUrl;
    }

    const AlertIcon = (
        <i style={{ color: "#000000", display: "flex", alignItems: "center" }} className="icon">
            <img alt="" style={{ color: "#000000" }} width={"55%"} height={"55%"} src={Alert} />
        </i>
    );

    // Callback for the verify class to update the component
    const adapterCb = (event, data, e) => {
        switch (event) {
            case 'validated':
                setVerifyData(data);
                break;;
            case 'wait':
                props.states.setLoading(data);;
                return;;
            case 'error':
                switch (e) {
                    case 1:
                        setErrMsg("The requested news release has not made it into the chain yet. Please wait another five hours and then retry this news release.")
                        break;;
                    case 2:
                        setErrMsg("The requested news release has not made it into the chain yet. Please wait another five hours and then retry this news release.")
                        break;;
                    default:
                        if (e) {
                            setErrMsg("An unexpected error has occurred in the backend server. This event has been logged and will be reviewed by our engineering team as soon as possible.")
                            break;;
                        }
                        setErrMsg("A network error has occurred. For more information, please email us at errorfulltransparency@verizon.com.")
                        break;;
                }
                setValidUrl(false)
                setVerifyData(false);;
                break;;
            default:
                console.log(event)
        }
        props.states.setUpdateView((updateView) => ++updateView);
        props.states.setLoading(false);
    }

    // Display the verification data returned from the server
    const proof = () => {
        if (verifyData && !verifyData['error']) {
            return (
                <Grid centered className="dataContent">
                    <Grid.Row>
                        <Grid divided="vertically" columns="equal" stackable>
                            <Grid.Row>
                                {['issuer', 'data_hash', 'revision_number'].map((e, i) => {
                                    if (!verifyData[e]) {
                                        return null;
                                    }
                                    let { body, head } = ctxVerify.getAbout(e, !verifyData[e]['failure']);

                                    return (
                                        <Grid.Column textAlign='left' key={i}>

                                            <Segment style={{ padding: 0, backgroundColor: '#F6F6F6', height: '100%' }}>

                                                <div
                                                    style={{ whiteSpace: 'pre-wrap', wordBreak: 'keep-all' }}
                                                    className={`${verifyData[e]['failure'] ? 'error' : 'success'}`}
                                                >
                                                    <Header className="titleForm large" content={(e.charAt(0).toUpperCase() + e.slice(1)).split('_')[0]}/>
                                                    <h5>{head}</h5>
                                                </div>

                                                <div>
                                                    <Header className="titleForm small" content="Token"/>
                                                    <h5>{verifyData[e]['token']}</h5>
                                                </div>

                                                <div>
                                                    <Header className="titleForm small" content="Blockchain"/>
                                                    <h5>{verifyData[e]['blockchain']}</h5>
                                                    {
                                                        !verifyData[e]['failure'] &&
                                                            <h5 style={{ whiteSpace: 'pre-wrap', wordBreak: 'keep-all', paddingTop: '8px' }}>
                                                                Leave verizon.com to view on the&nbsp;
                                                                <a style={{ textDecoration: 'underline' }} rel="noreferrer" target="_blank" href={verifyData[e]['bc_url']}>
                                                                    Polygon Block Explorer
                                                                </a>.
                                                            </h5>
                                                    }
                                                </div>

                                                {
                                                    verifyData[e]['hashed_data'] &&
                                                    <div>
                                                        <Header className="titleForm small" content="Calculated"/>
                                                        <h5>{verifyData[e]['hashed_data']}</h5>
                                                    </div>
                                                }

                                                <div>
                                                    <Header className="titleForm small" content="Description"/>
                                                    <h5 style={{ whiteSpace: 'pre-wrap', wordBreak: 'keep-all' }}>{body}</h5>
                                                </div>

                                            </Segment>

                                        </Grid.Column>
                                    )
                                })
                                }
                            </Grid.Row>
                        </Grid>
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Grid stackable columns={2} style={{ padding: 0 }}>
                            <Grid.Row style={{ paddingTop: 0, paddingBottom: 0, gap: "12px" }}>
                                <Grid.Column className={"btnGrid"} textAlign="center" style={{ width: "197px", padding: 0 }}>
                                    <Button
                                        className="revisionBtn white"
                                        disabled={!Boolean(verifyData['prev_revision'])}
                                        onClick={
                                            (e, v) => {
                                                if (Boolean(verifyData['prev_revision'])) {
                                                    handleSubmit(e, v, ctxVerify.base64Decode(verifyData['prev_revision']));
                                                }
                                            }
                                        }
                                    >
                                        Previous
                                    </Button>
                                </Grid.Column>
                                <Grid.Column className={"btnGrid"} textAlign="center" style={{ width: "197px", padding: 0 }}>
                                    <Button
                                        className="revisionBtn white"
                                        disabled={!Boolean(verifyData['next_revision'])}
                                        onClick={
                                            (e, v) => {
                                                if (Boolean(verifyData['next_revision'])){
                                                    handleSubmit(e, v, ctxVerify.base64Decode(verifyData['next_revision']));
                                                }
                                            }
                                        }
                                    >
                                        Next
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Row>
                </Grid>
            );
        }
    }

    // Loading if app not initialized
    if (!ctxVerify) {
        return (
            <>
                <Dimmer page active={Boolean(props.states.isLoading)}>
                    <Loader>{String(props.states.isLoading)}</Loader>
                </Dimmer>
            </>
        )
    }
    // App display
    else {
        return (
            <>
                <div style={{ minHeight: "97vh" }}>
                    <Grid centered style={{ minWidth: "100vw" }}>
                        <Grid.Column style={{ backgroundColor: "#000000", maxHeight: '20em', minHeight: "12em" }} verticalAlign="middle" className={lstyle.bannerMainCol}>
                            <Container fluid style={{ overflow: "hidden", maxHeight: '20em', }}>
                                <Image alt="" style={{ overflow: "hidden", maxHeight: '17em' }} className="herogif" src={TransGif} />
                                <Container style={{ 'paddingTop': '2em' }}>
                                    <Grid>
                                        <Grid.Column width={16} textAlign="left" verticalAlign="middle" className={lstyle.bannerCol}>
                                            <Image alt="Verizon Checkmark Logo" src={VeriCheckmark} floated="left" className={lstyle.verizonCheckLogo}/>
                                            <div>
                                                <Header as="h1" className={lstyle.verizonH1}>Full Transparency</Header>
                                                <Header as="h2" className={lstyle.verizonH2}>by Verizon</Header>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Container>

                            </Container>
                        </Grid.Column>
                    </Grid>

                    <Container style={{ 'paddingTop': '2em', 'paddingLeft': '9px', 'paddingRight': '9px' }}>
                        <Grid className="mainContent">
                            <Grid.Row style={{ 'paddingTop': '2px', 'paddingLeft': '-1rem', 'paddingRight': '-1rem' }} textAlign='left'>
                                <Header className="herotext2" as="h3">Block explorer</Header>
                            </Grid.Row>

                            <Grid.Row style={{ padding: 0, paddingBottom: '8px' }} textAlign='left'>
                                <div style={{ display: "block", textAlign: "left", width: "100%" }}>
                                    <p>This tool allows you to easily check Full Transparency against the blockchain and independently confirm that the system behind the initiative is working. To begin, enter the URL of any official Verizon news release published on or after October 30, 2020.</p>
                                </div>
                            </Grid.Row>

                            <Grid.Row style={{ padding: 0, paddingBottom: '8px' }} textAlign='left'>
                                <div style={{ display: "block", textAlign: "left", width: "100%" }}>
                                    <p>If the URL is a verified match for an officially tracked news release, the system will collect the information necessary to validate the proof associated with it. You will then see the additional details necessary to check the authenticity and integrity of the Full Transparency Initiative proofs.</p>
                                </div>
                            </Grid.Row>

                            <Grid.Row>
                                <p style={{ 'fontSize': '14px', 'marginBottom': '4px', 'color': '#000000', textAlign: 'left' }}>Verizon News Release URL</p>
                                <Input
                                    aria-label={"Enter URL"}
                                    icon={!getValid() ? AlertIcon : false}
                                    error={!getValid()}
                                    className="inputBar"
                                    onChange={handleChange}
                                    value={verifyURL}
                                    placeholder="Enter URL"
                                    fluid
                                >
                                </Input>
                                {!getValid() && !errMsg ?
                                    (<p style={{ 'fontSize': '14px', 'marginLeft': '2px', 'marginBottom': '0px', 'marginTop': '8px', 'color': '#000000', textAlign: 'left' }}>The entry is not a URL. Please enter a URL using the prefix https://www.</p>) :
                                    errMsg ?
                                        (<p style={{ 'fontSize': '14px', 'marginLeft': '2px', 'marginBottom': '0px', 'marginTop': '8px', 'color': '#000000', textAlign: 'left' }}>{errMsg}</p>) :
                                        (<></>)
                                }
                            </Grid.Row>
                            <Grid.Row className="verifyBtn">
                                <div>
                                    <Button onClick={handleSubmit}>Verify</Button>
                                </div>

                            </Grid.Row>
                        </Grid>

                    </Container>
                    <Container style={{ padding: '2em 9px' }}>
                        {proof()}
                    </Container>

                </div>
                <Grid centered>
                    <Grid.Row className="footer">
                        <Menu text compact>
                            <Menu.Item>
                                <a className="footLink" rel="noreferrer" target="_blank" href="https://www.madnetwork.com/">MadNetwork</a>
                            </Menu.Item>
                            <Menu.Item>
                                <a className="footLink" rel="noreferrer" target="_blank" href="https://www.adledger.org/">AdLedger</a>
                            </Menu.Item>
                        </Menu>
                    </Grid.Row>
                </Grid>

            </>
        )
    }
}
export default MainView;
