const axios = require("axios");
const GoHash = require("./GoHash.js");

class Verify {
  constructor(cb, domain) {
    this.cb = cb;
    this.domain = domain;
    this.errorCode = false;
  }

  // main function to verify data based on url
  async verifyData(p) {
    try {
      this.cb("wait", "Verifying data...");
      let ps = p.split("/");
      let a = "";
      if (
        !isNaN(parseInt(ps[ps.length - 1])) &&
        typeof parseInt(ps[ps.length - 1]) === "number" &&
        /^\d+$/.test(ps[ps.length - 1])
      ) {
        a += this.base64URL(ps.slice(0, ps.length - 1).join("/"));
        a += "/" + ps[ps.length - 1];
      } else {
        a = this.base64URL(p);
      }
      let d = await this.getData(a);
      if (this.errorCode) {
        throw d;
      }

      let k = Object.keys(d);
      let failures = 0;
      for (let i = 0; i < k.length; i++) {
        if (k[i] === "prev_revision" || k[i] === "next_revision") {
          continue;
        }
        if (d[k[i]]["token"] !== d[k[i]]["blockchain"]) {
          d[k[i]]["failure"] = true;
          failures += 1;
          continue;
        }
        if (d[k[i]]["raw_data"]) {
          let h = await this.hash(d[k[i]]["raw_data"]);
          d["data_hash"]["hashed_data"] = h;
          if (h !== d[k[i]]["token"] || h !== d[k[i]]["blockchain"]) {
            d[k[i]]["failure"] = true;
            failures += 1;
            continue;
          }
        }
        d[k[i]]["success"] = true;
      }

      if (failures === 0) {
        d["pass"] = true;
      }

      this.cb("validated", d);
    } catch (ex) {
      this.cb("error", String(ex), this.errorCode);
    }
  }

  // Get verification data from verify server
  async getData(p) {
    try {
      this.errorCode = false;
      let res = await axios.get(this.domain + p);
      if (!res || !res.data) {
        throw new Error("Invalid URL!");
      }
      if (res.data["error"]) {
        this.errorCode = res.data["error"];
        return res.data["message"];
      }
      return res.data;
    } catch (ex) {
      throw String(ex);
    }
  }

  // string to url safe base64
  base64URL(s) {
    try {
      let b64 = btoa(s);
      b64 = b64.replace(/\+/g, "-").replace(/\//g, "_").replace(/\=+$/, ""); // eslint-disable-line
      return b64;
    } catch (ex) {
      throw new Error("Could not encode string to base64");
    }
  }

  // decode base64
  base64Decode(s) {
    try {
      let ps = s.split("/");
      let d = "";
      if (
        !isNaN(parseInt(ps[ps.length - 1])) &&
        typeof parseInt(ps[ps.length - 1]) === "number" &&
        /^\d+$/.test(ps[ps.length - 1])
      ) {
        d += atob(ps.slice(0, ps.length - 1).join("/"));
        d += "/" + ps[ps.length - 1];
      } else {
        d = atob(d);
      }
      return d;
    } catch (ex) {
      throw new Error("Could not decode string to base64");
    }
  }

  // merkel trei hash
  async hash(v) {
    try {
      let hash = await GoHash.MerkelHasher(v);
      return "0x" + hash;
    } catch (ex) {
      throw String(ex);
    }
  }

  // verification field information
  getAbout(t, s) {
    switch (t) {
      case "issuer":
        return this.issuerAbout(s);
      case "data_hash":
        return this.dataAbout(s);
      case "revision_number":
        return this.revisionAbout(s);
      default:
        return "";
    }
  }

  // issuer information text
  issuerAbout(s) {
    let b = `
        A pass result confirms the issuer is the entity that claims to be the authoring 
        entity. We must validate that the blockchain account specified in the proof object points to an 
        account that contains a validly signed certificate from the AdLedger Public Key Infrastructure 
        (PKI). This certificate should also name the same entity as the issuer, as is specified in the proof 
        object. Once this is confirmed the issuer check is considered successful.
        `;
    b = b.replace(/  |\r\n|\n|\r/gm, ""); // eslint-disable-line
    let h;
    if (s) {
      h = "Issuer check was successful";
    } else {
      h = "Issuer check failed";
    }
    return { body: b, head: h };
  }

  // data information text
  dataAbout(s) {
    let b = `
        The data field, in this context, references the hash of the content of the specific news 
        release. This hash is built by folding the chunked data into a Merkle Tree and using the 
        root hash as the hash of the data. This field exists in the proof object, on the MadNet 
        blockchain, and is locally calculated in the browser during validation. 
        Once the raw content of the data associated with the news release properly hashes to 
        the value stored in blockchain and the blockchain value also matches the issued proof 
        value, the data check is considered successful.       
        `;
    b = b.replace(/  |\r\n|\n|\r/gm, ""); // eslint-disable-line
    let h;
    if (s) {
      h = "Data check was successful";
    } else {
      h = "Data check failed";
    }
    return { body: b, head: h };
  }

  // revision information text
  revisionAbout(s) {
    let b = `
        The revision number shows how many times a news release has been updated. 
        This number starts at zero and increases by one each time a new version of 
        the news release is published by the content management system (CMS). 
        Once the revision number claimed in the proof is confirmed to exist in the 
        associated blockchain data as a valid news release, the revision check is 
        considered successful.
        `;
    b = b.replace(/  |\r\n|\n|\r/gm, ""); // eslint-disable-line
    let h;
    if (s) {
      h = "Revision check was successful";
    } else {
      h = "Revision check failed";
    }
    return { body: b, head: h };
  }
}

export default Verify;
