import React, { useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'

import { Dimmer, Loader } from 'semantic-ui-react';
import 'style/app.css';
import MainView from 'pages/MainView/MainView.js';
import Errors from "components/errors.js";
import AppContextWrap from 'context/AppContext.jsx';

/**
 * Main App
 * <Store> used for context, allowing childern in <MainView/> to share Store state 
 */
function App() {

  /**
 * Props for childern components to update main view
 * Refresh, Loading, Errors, Update View
 */
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [updateView, setUpdateView] = useState(0);
  const [activeMadnetPanel, setMadnetPanel] = useState(false);
  const madnetSetup = useRef(false);

  // Object for the props to be used in childern components
  const propStates = {
    isLoading,
    setLoading,
    isError,
    setError,
    updateView,
    setUpdateView,
    madnetSetup,
    activeMadnetPanel,
    setMadnetPanel,
  }


return (
    <div>
      <AppContextWrap>
        <Router>
          <Dimmer page active={Boolean(isLoading)}>
            <Loader>{String(isLoading)}</Loader>
          </Dimmer>
          <Errors states={propStates} />
          <Switch>
            <MainView states={propStates} />
          </Switch>
        </Router>
      </AppContextWrap>
    </div>
  );
}

export default App;
